<template>
  <v-row>
    <v-col
      cols="12"
      class="item-list"
    >
      <!-- The title toolbar -->
      <v-app-bar
        flat
        color="transparent"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="actionIcon.length > 0 || actionText.length > 0"
          text
          rounded
          @click="actionClick"
        >
          <v-icon
            v-if="actionIcon.length > 0"
            class="pr-1"
          >
            {{ actionIcon }}
          </v-icon>
          <span v-if="actionText.length > 0">
            {{ actionText }}
          </span>
        </v-btn>
      </v-app-bar>
      <!-- The list itself-->
      <v-list
        v-if="listItems.length > 0"
        three-line
        background-color="transparent"
      >
        <template v-for="(item, index) in listItems">
          <!--<v-subheader v-if="item.header">{{ item.header }}</v-subheader>-->
          <!--<v-divider v-else-if="item.divider" :inset="item.inset"></v-divider>-->
          <v-list-item
            :key="'itemID_'+item.id+'_index_'+index"
            ripple
            class="v-card mb-1"
            @click="itemClick(item)"
          >
            <v-list-item-avatar size="48">
              <v-img
                v-if="!item.useFaceIcon && avatarList[item.chosen_userid]"
                :src="avatarList[item.chosen_userid]"
                @error.stop.prevent="listItems[index].useFaceIcon = true"
              />
              <v-icon v-else>
                mdi-face
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.subject }}</v-list-item-title>
              <v-list-item-subtitle class="text--primary">
                {{ item.content }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="hasChips(item) && !loading">
                <v-chip
                  v-for="chip in getChips(item)"
                  :key="chip.key"
                  label
                >
                  <v-avatar
                    v-if="chipImages"
                    :class="chip.tag"
                    tile
                  >
                    <v-img
                      v-if="chip.imgURL"
                      :src="chip.imgURL"
                    />
                    <v-icon v-else-if="chip.icoURL">
                      {{ chip.icoURL }}
                    </v-icon>
                  </v-avatar>

                  {{ chip.name }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <!-- Some alt text about the user and the time created -->
              <v-list-item-action-text>{{ item.username }}</v-list-item-action-text>
              <v-list-item-action-text>{{ timeStringConv(item.timestamp) }}</v-list-item-action-text>
              <!-- The sticky icons -->
              <v-icon
                v-if="item.sticky === 'yes'"
                color="red darken-2"
                large
                :style="{'visibility': stickyIcons ? 'visible' : 'hidden'}"
              >
                {{ stickyOnIcon }}
              </v-icon>
              <v-icon
                v-else
                color="grey lighten-2"
                large
                :style="{'visibility': stickyIcons ? 'visible' : 'hidden'}"
              >
                {{ stickyOffIcon }}
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ItemList',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Items'
    },
    listItems: {
      type: Array,
      required: true,
      default () { return [] }
    },
    chipImages: {
      type: Boolean,
      required: false,
      default: false
    },
    actionIcon: {
      type: String,
      required: false,
      default: ''
    },
    actionText: {
      type: String,
      required: false,
      default: ''
    },
    stickyIcons: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    stickyOnIcon: {
      type: String,
      required: false,
      default: 'mdi-alert'
    },
    stickyOffIcon: {
      type: String,
      required: false,
      default: 'mdi-alert'
    }
  },
  data () {
    return {
      avatarList: []
    }
  },
  mounted () {
    // TODO: Make into a global list
    this.$root.$data.si.getUserData(respData => {
      this.avatarList = []

      respData.members.forEach(member => {
        this.avatarList[member.user_id] = this.$root.$data.formAvatarURL(member.profile_picture)
      })
    })
  },
  methods: {
    actionClick: function () {
      this.$emit('actionClicked')
    },
    itemClick: function (item) {
      // console.log('Clicked on item with id', item.id)

      this.$emit('itemClicked', Number(item.id))
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    hasChips: function (item) {
      const files = item.files

      if (files) {
        if (files.length > 0) {
          return true
        }
      }

      return false
    },
    getChips: function (item) {
      const chips = []

      if (this.hasChips(item)) {
        item.files.forEach(file => {
          const imgURL = this.formImageURL(file.filename, 'chip')
          const tagColor = this.formTagColor(file.tag)
          const icoURL = 'mdi-' + file.icon
          chips.push({ name: file.name, imgURL: imgURL, key: file.filename, tag: tagColor, icoURL: icoURL })
        })
      }

      return chips
    },
    formImageURL: function (filename, version) {
      if (filename) {
        return 'https://cmsdb.darkcosmos.org/files/viewnote.php?file=' + filename + '&version=' + version + '&apikey=' + localStorage.getItem('devAPIKey')
      } else {
        return null
      }
    },
    formTagColor: function (tag) {
      switch (tag) {
        case 'ok':
          return 'avatar--green'
        case 'warn':
          return 'avatar--amber'
        case 'fail':
          return 'avatar--red'
        default:
          return 'avatar--white'
      }
    }
  }
}
</script>

<style scoped>

  .avatar--white {
    background: transparent;
  }

  .avatar--green {
    background: #4CAF50;
    color: white;
  }

  .avatar--amber {
    background: #FFC107;
    color: white;
  }

  .avatar--red {
    background: #F44336;
    color: white;
  }

</style>
